import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './cars.module.scss';
// import Footer from "../../components/shared/Footer/Footer";

// import {graphql, useStaticQuery} from 'gatsby';
// import {getImagesFluid} from '../../components/componentsUtils';
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import SlideTypeG from "../../components/shared/ImageSlider/SlideTypeG";
// import Movie from "../../movies/Inspirations/Automotive/inspirations-cars.mp4";
// import CarsIconBlack from "../../images/icons/car_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";
// import YouTubeIconGreen from "../../images/icons/play_video_green.svg";


// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_Automotive"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;

// const AutomotivePage = () => {
//   const { t } = useTranslation();
//   const pageTitle = `${getRoutes().Automotive.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

  
//   const imagesFluid = getImagesFluid(useStaticQuery(query));

//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Pro image is impacting the fast sale of your vehicle'}/>

//       <div className={css.wrapper}>

//       <div className={css.slide2} id={getRoutes().Automotive.sections.getStarted}>
//         <div className={css.wrapper}>
//         <div className={css.slide1}>
//           <CoveredVideo
//               videoSrcURL={Movie}
//               placeholderImage={'Cars_1_placeholder.jpg'}
//               imagesFluid={imagesFluid}
//               icon={CarsIconBlack}
//               title="Automotive"
//               text="Get all important details to make the right choice."
//               buttons={(
//                 <>
//                     <GoToButton
//                       sectionUrl={getLink([getRoutes().Automotive, getRoutes().Automotive.sections.getStarted])}
//                       text="Get started!"
//                     />
//                     <GoToButton
//                         externalPageUrl={'https://youtu.be/8RARbyCTLHY'}
//                         icon={YouTubeIconGreen}
//                         filled={false}
//                     />
//                 </>
//               )}
//           />
//         </div>
//         <div className={css.slide2} id={getRoutes().Automotive.sections.getStarted}>
//           <SlideTypeF
//               title={<>Professional photo <br/>quality enhancement</>}
//               text3={<p>Final optimisation result is just a visual improvement, <br/>without changing or modifying true context of the photo.</p>}
//               fullImage={'Cars_2.jpg'}
//               fullImageAlt={"optimisation anonymisation auto cropping"}
//               phoneImage1={'Cars_mobile_1.jpg'}
//               phoneImage1Alt={"optimisation anonymisation auto cropping"}
//               phoneImage1Height={1100}
//               imagesFluid={imagesFluid}
//               imagePosition={'right'}
//           />
//         </div>
//         <div className={css.slide3}>
//           <SlideTypeF
//               title={<>Professional photo <br/>quality editing</>}
//               text3={<p>Editing selected functionality interferes into <br/>the photo parameters and the final result <br/>is a manipulated reconstruction.</p>}
//               fullImage={'Cars_3.jpg'}
//               fullImageAlt="inpainting upscaling artifacts removing"
//               phoneImage1={'Cars_mobile_4.jpg'}
//               phoneImage1Alt="inpainting upscaling artifacts removing"
//               phoneImage1Height={1100}
//               imagesFluid={imagesFluid}
//           />
//         </div>
//         <div className={css.slide4}>
//           <SlideTypeG
//               title="Define your workflow requirements"
//               fullImage={'Cars_4.jpg'}
//               phoneImage={'Cars_mobile_7.jpg'}
//               alt="Custom workflow"
//               imagesFluid={imagesFluid}
//           />
//         </div>
//     </div>
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default AutomotivePage;
